import React, { useState } from 'react'
import PropTypes from 'prop-types'
import logo from './logo.png'
import { Alert, Form, Button } from 'react-bootstrap'
import User from './User'

async function loginUser(credentials) {
  var body = JSON.stringify(credentials)
  var response = await fetch('https://auth.mrthospitality.com/authenticate', {
    method: 'POST',
    body: body
  })

  if(response.ok){
    return await response.text()
  }
 }
 

export default function Login({ setToken }){
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async e => {
    var mounted = true
    try{
      setDisable(true)
      e.preventDefault();
      const token = await loginUser({
        email,
        password
      });
      if(token){
        User.setCurrentUserFromToken(token)
        setToken(token)
        localStorage.setItem("pineapple", token)
        mounted = false
      }
      else{
        setError(true)
      }
      
    }
    finally{
      if(mounted) setDisable(false)
    }
  }

  return(
  <div className="App">
    <div className="LoginContainer">
      <div className="LoginBox">
        <Form onSubmit={handleSubmit}>
          <img alt="Cheers of Ramona" src={logo} width="255"></img>
          {error ? <Alert variant="danger">Invalid username or password.</Alert> : null }
          <div>
          <Form.Control autoComplete="username" isInvalid={error} disabled={disable} type="email" placeholder="Email" style={{width:"100%"}} onChange={e => setEmail(e.target.value)}/>
          </div>
          <div>
          <Form.Control autoComplete="current-password" isInvalid={error} disabled={disable} type="password" placeholder="Password" style={{width:"100%"}} onChange={e => setPassword(e.target.value)}/>
          </div>
          <div>
            <Button disabled={disable} className="btn btn-primary submit" style={{width:"100%"}} type="submit">{ !disable ? <span className=".button-text">Login</span> : <i className="fa fa-cog spinner"></i> }</Button>
          </div>
        </Form>              
      </div>
    </div>
  </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}