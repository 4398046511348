import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './Login'
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Form } from 'react-bootstrap'
import Tasks from './Tasks'
import checklisttemplate from './checklist.template.json'
import ExhaustedItems from './ExhaustedItems'

function AutoSave(props){
  if(props.saveState.saving){
    return <i className="fa fa-save flasing auto-save"></i>
  }
  if(props.saveState.saved){
    return <i className="fa fa-check-circle auto-save"></i>
  }
  if(props.saveState.error){
    return <i className="fa fa-exclamation-circle auto-save" title={props.saveState.error}></i>
  }
  return null
}

var timeoutid = null

function App() {
  const [sse, setSSE] = useState();
  const [exhaustedItems, setExhaustedItems] = useState();
  const [token, setToken] = useState(localStorage.getItem("pineapple"));
  const [saveState, setSaveState] = useState({saving: false, saved: false, error: null})
  const [loadingState, setLoadingState] = useState({ loading: false, loaded: false, error: null})
  const [checklistItems, setChecklistItems] = useState({tasks: []})

  useEffect(() => {
    if(!sse){
      if(token){
        const newSSE = new EventSource('/api/events?token=' + token)
        setSSE(newSSE);
        newSSE.onmessage = e => {
          const message = JSON.parse(e.data)
          console.log(message)
          if(message.exhaustedItems){
            setExhaustedItems(message.exhaustedItems)
          }
          if(message.tasks){
            setChecklistItems(message)
          }
        }
        newSSE.onerror = (err) => {
          // error log here 
          console.error(err)
          newSSE.close();
          setSSE(null)
        }
      }
    }
  }, [sse, token])

  const doSave = async (data) => {
    setSaveState({saving: true, saved: false, error: false})
    fetch('/api/checklist', {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(r => {
      if(r.ok){
        setSaveState({...saveState, saved: true})
      }else{
        setSaveState({...saveState, error: r.status + " : " + r.statusText })
      }
    }).catch(e => {
      setSaveState({...saveState, error: e})
    })
  }

  const getData = async () => {
    fetch('/api/checklist', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }).then(r => {
      if(r.ok){
        r.text().then(data => {
          var loadedData = checklisttemplate
          var serverData = JSON.parse(data)
          if(serverData.tasks.length > 0){
            loadedData = serverData
          }
          setLoadingState({...loadingState, loaded: true})
          setChecklistItems(loadedData)
        })
      }else{
        setLoadingState({...loadingState, error: r.status + " : " + r.statusText })
      }
    }).catch(e => {
      setLoadingState({...loadingState, error: e })
    })
  }

  if(!token) {
    return <Login setToken={setToken} />
  }

  if(!loadingState.loading && !loadingState.loaded && !loadingState.error){
    getData()
    setLoadingState({...loadingState, loading: true})
  }

  const handleSave = (data) => {
    if(timeoutid){
        clearTimeout(timeoutid)
    }
    timeoutid = setTimeout(() => { doSave(data) }, 1500)
  }

  var body = <div><i className="fa fa-spinner spinner loading"></i></div>
  if(loadingState.error){
    body = <div><i className="fa fa-exclamation-circle loading" title={loadingState.error}></i><div>{loadingState.error}</div></div>
  }
  if(loadingState.loaded){
    body = <div className="scroll" style={{maxHeight: 'inherit', overflowY: 'auto'}}>
      <ExhaustedItems items={exhaustedItems} token={token}/>
      <Tasks tasks={checklistItems.tasks} onSave={() => {handleSave(checklistItems)}}></Tasks>
      </div>
  }

  return (
    <div className="App">
      <div className="AppContainer">
        <AutoSave saveState={saveState}></AutoSave>
        <fieldset disabled={saveState.saving} style={{maxHeight: 'inherit', }}>
          <Form onSubmit={(event) => event.preventDefault()} className='scroll-container' style={{verticalAlign: 'center', textAlign: 'center', maxHeight: 'inherit', overflow: 'hidden'}}>
              {body}
          </Form>
        </fieldset>
      </div>
    </div>
  );
}

export default App;
