import { Form } from 'react-bootstrap'
import propTypes from 'prop-types'
import { useState } from 'react'
import './ExhaustedItems.css'
import { Alert } from 'react-bootstrap'
import arrayEqual from 'array-equal'

export default function ExhaustedItems(props){
    const [loaded, setLoaded] = useState(props.items ? true :false)
    const [exhaustedItemName, updateExhaustedItem] = useState(props.value)
    const [exhaustedItems, updateItems] = useState(props.exhaustedItems ?? [])

    const doSave = async (data) => {
        fetch('/api/exhausted-items', {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            'Authorization': 'Bearer ' + props.token,
          }
        })
      }
    
      const getData = async () => {
        fetch('/api/exhausted-items', {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + props.token,
          }
        }).then(r => {
          if(r.ok){
            r.text().then(data => {
              var serverData = JSON.parse(data)
              if(serverData.exhaustedItems.length > 0){
                updateItems(serverData.exhaustedItems)
              }
            })
        }}).finally(()=> {setLoaded(true)})
      }

    if(!loaded){
        getData()
    }

    if(props.items){
      if (!arrayEqual(props.items, exhaustedItems)){
        const newItems = props.items
        delete props.items
        updateItems(newItems)
      }
    }

    const handleReturn = (event) => {
        if ( event.key === 'Enter' ){
            handleAdd()
        }
    }

    const handleAdd = () => {
        if(exhaustedItemName && exhaustedItemName.trim() !== ""){
            const dataToSave = [...exhaustedItems, exhaustedItemName]
            doSave(dataToSave)
            updateItems(dataToSave)
            updateExhaustedItem("")
        }
    }

    const handleDelete = (deleteItemValue) => {
        const dataToSave = exhaustedItems.filter(item => item !== deleteItemValue)
        doSave(dataToSave)
        updateItems(dataToSave)
    }

    var itemsHtml = exhaustedItems.map(item =>
        <Alert style={{padding: "0px", paddingLeft: "3px", marginRight: "5px"}}>
            {item}<i onClick={() => handleDelete(item)} className="fa fa-minus-circle delete"></i>
        </Alert>
    )

    const addItemControl = 
        <div className="row-item" style={{display: "flex", flexDirection: "row"}}>
            <Form.Control onKeyPress={handleReturn} value={exhaustedItemName} onChange={(event) => updateExhaustedItem(event.target.value)} type="text" placeholder="missing item..." style={{width: "100%"}} />
            <i onClick={handleAdd} className="fa fa-plus-circle add"></i>
        </div>

    return(
        <div>
            <fieldset className="warning-border" style={{textAlign: "center", minHeight: "100px"}}>
            <Form.Label className="title"><i className="fa fa-exclamation-triangle warning" />86'd Items<i className="fa fa-exclamation-triangle warning" /></Form.Label>
            <div className="row-item" style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
                {itemsHtml}
            </div>
            {loaded ? addItemControl : null}
            </fieldset>
        </div>
    )
}

ExhaustedItems.propTypes = {
    token: propTypes.string.isRequired,
    items: propTypes.array,
}