import { useState } from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import qs from 'query-string'
import Today from './today'
import { useLocation } from 'react-router-dom'

function checkChildrenChecked(parent){
    if(parent){
        if((parent.tasks || []).every(task => task.checked)){
            return true
        }
    }
    return false
}

const ShowTask = (t) => {
    const location = useLocation()
    const urlQuery = qs.parse(location.search)
    
    if ( (!t.shift || !urlQuery.shift || t.shift === urlQuery.shift) &&
        (!t.role || !urlQuery.role || t.role === urlQuery.role) &&
        (!t.day || t.day === new Today().getDayDayOfWeekName())){
            return true;
        }

    return false;
}

function uncheckChildren(task){
    (task.tasks || []).forEach(task => task.checked = false)
}

function checkChildren(task){
    (task.tasks || []).forEach(task => task.checked = true)
}

function Task(props){
    if (props.task.checked===undefined) {
        props.task.checked=false
    }

    const [state, setState] = useState({parent: props.parent, task: props.task})
    props.task.setState = setState
    props.task.handleChecked = () =>{
        if(!props.parent){
            if(state.task.checked)
                uncheckChildren(props.task)
            else
                checkChildren(props.task)
        }
        props.task.checked = !state.task.checked
        if(checkChildrenChecked(props.parent)){
            props.parent.checked = true
            props.save()
            props.parent.setState({parent: props.parent.parent, task: props.parent})
        }else{
            if(props.parent && props.parent.checked !== props.task.checked){
                props.parent.checked = false
                props.save()
                props.parent.setState({parent: props.parent.parent, task: props.parent})
            }
            else{
                props.save()
                props.task.setState({parent: props.parent, task: props.task})
            }
        }
    }

    const subtasks = ( props.task.tasks || [] ).map((task) => {
        if(ShowTask(task)){
            return <Task key={task.id} parent={props.task} task={task} save={props.save}></Task>
        }
        return null
    })

    var classes = []
    if(props.parent)
        classes.push("sub-task")
    else
        classes.push("task")

    if(props.task.checked)
        classes.push("checked")
    
    return (
        <div>
            <Form.Check 
                id={props.task.id} 
                className={classes.join(" ")} 
                label={props.task.name} 
                checked={props.task.checked}
                onChange={props.task.handleChecked.bind()}
                ></Form.Check>
            {subtasks}
        </div>
    )
}

Task.propTypes ={
    task: PropTypes.object.isRequired,
    parent: PropTypes.object,
    save: PropTypes.func.isRequired
}

export default function Tasks(props){
    const tasks = props.tasks.map((task) => {
        if(ShowTask(task)){
            return (
                <Task key={task.id} parent={props.parent} task={task} save={props.onSave}></Task>
                )
        }
        return null
    })
    return tasks
}

Tasks.propTypes = {
    parent: PropTypes.object,
    tasks: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired
}