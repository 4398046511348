
export default class Today{

    constructor(){
        this.setCurrentDay()
    }

    setCurrentDay(){
        var today = new Date()
        if(today.getHours()<4){
            today.setDate(today.getDate()-1)
        }
        this.date = today
      }

    getDayDayOfWeekName(){
        var weekday = new Array(7);

        weekday[0] = "Sunday";
        weekday[1] = "Monday";
        weekday[2] = "Tuesday";
        weekday[3] = "Wednesday";
        weekday[4] = "Thursday";
        weekday[5] = "Friday";
        weekday[6] = "Saturday";

        return weekday[this.date.getDay()].toLowerCase();    
    }

    getCurrentShift(){
        if(this.today.getHours() < 17){
            return "am";
        }
        else{
            return "pm";
        }
    }
}
